/*
 * @Author: Yin Yin@qq.com
 * @Date: 2024-11-12 14:50:18
 * @LastEditors: Yin Yin@qq.com
 * @LastEditTime: 2024-11-14 11:31:34
 * @FilePath: \shortlink-vue\src\api\shortlink.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '../utils/request'


//添加部门
export function queryshorlink(params) {
    return request({
        url: "/api/shortlink/short_link/queryshorlink",
        method: 'post',
        data: params
    })
}